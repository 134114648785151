import { Component, Renderer2 } from '@angular/core';
import Intercom from '@intercom/messenger-js-sdk';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './core/helpers/language.service';
import { LocalStorageService } from './core/helpers/local-storage.service';

Intercom({
  app_id: 'nuojv8i8'
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'scrub-valley-system';

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private localStorageService: LocalStorageService,
    private renderer: Renderer2
  ) {
    const supportedLangs = ["en", "ar"];
    this.translate.addLangs(supportedLangs);
    this.translate.setDefaultLang(this.localStorageService.getItem("lang") ?? 'en');
    supportedLangs.forEach((language) => {
      this.translate.reloadLang(language);
    });
  }

  ngOnInit(): void {
    this.languageService.currentLang$.subscribe(lang => {
      this.renderer.setAttribute(document.documentElement, 'lang', lang);
      if (lang === 'ar') {
        this.renderer.setAttribute(document.documentElement, 'dir', 'rtl');
      } else {
        this.renderer.setAttribute(document.documentElement, 'dir', 'ltr');
      }
    });
  }

}
